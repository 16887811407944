/// <reference types="googlemaps" />
// Angular
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
// Layout
import { AgmMarker, MapsAPILoader, GoogleMapsAPIWrapper } from '@agm/core';
import { MapsService } from '../../core/_base/services/map.service';
import Swal from 'sweetalert2';
import {FormControl} from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FarmaciaModel } from '../../core/_base/crud/models/farmacias/farmacia.model';
import { SplashScreenService } from '../../core/_base/services/splash-screen.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import $ from "jquery";


@Component({
	selector: 'kt-auth',
	templateUrl: './map.component.html',
	styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, AfterViewInit {
	// Variable para ocultar las opciones de autocompletar
	showAutocomplete: boolean = false;
	// Filtro para las opciones de autocompletar
	filteredOptions: Observable<string[]>;
	// Variable con el valor del producto a buscar
	producto = new FormControl('');
	// Variable con el valor del código postal a buscar
	codigoPostal = new FormControl('');
	// Arreglo provisional para llenar las opciones del autocompletar
	options: string[] = ['One mias', 'Twoijhusn', 'Three udhkw'];
	// Variables de latitud, longitud y zoom del mapa
	lat;
	lng;
	zoom = 14;
	// Icono para mostrar la posición actual
	iconPos = {
		url: 'https://maps.google.com/mapfiles/kml/pal4/icon57.png',
		scaledSize: {
			width: 30,
			height: 30
		}
	};
	iconFarm = {
		url: 'https://maps.google.com/mapfiles/ms/icons/pink-dot.png',
		scaledSize: {
			width: 50,
			height: 50
		}
	};
	openedWindow : number = -1; // alternative: array of numbers
	mostrarRuta = false;
	// Variable para ocultar el marcador de rutas
	renderOptions = {
		suppressMarkers: true,
		polylineOptions: { strokeColor: 'rgba(0,174,210, 0)' }
	}
	// Arreglo provisional para posicionar farmacias en el mapa
	locations: FarmaciaModel[];
	// Opciones de configuración del mapa para ocultar etiquetas
	mapStyles = [
		{
			"featureType": "poi",
			"elementType": "labels",
			"stylers": [
				{
					"visibility": "off"
				}
			]
		}
	];
	// Variable utilizada para deshabilitar los espacios de búsqueda
	busqueda:boolean = false;

	// Variable utilizada para evitar un loop en la búsqueda
	errorBusqueda:boolean = false;

	images: Array<string>;
	secondImages: Array<string>;
	thirdImages: Array<string>;
	destinosMatrix = [];

	public origin: any;
	public destination: any;
	public facturacionURL: any;

	@ViewChild('search',{ read: ElementRef, static:false }) public searchElementRef: ElementRef;

	/**
	 * Component constructor
	 *
	 * @param el
	 * @param render
	 * @param translationService: TranslationService
	 * @param splashScreenService: SplashScreenService
	 */
	constructor(private mapsService: MapsService,
		private mapsAPILoader: MapsAPILoader,
		private changeDetectorRefs: ChangeDetectorRef,
		private splashScreenService: SplashScreenService){
			this.facturacionURL = environment.facturacionUrl;
			this.mapsAPILoader.load().then(() => {
				if (navigator.geolocation) {
					navigator.geolocation.getCurrentPosition(function (location) {
						Swal.close();
					},function(error){
					});
				}
			  });
		}
	
	ngOnInit(): void {
		this.images = ['./assets/media/products/geilen-publicidad-landing-001.png','./assets/media/products/geilen-publicidad-landing-002.png','./assets/media/products/geilen-publicidad-landing-003.png'];
		Swal.fire({
			title: '<h1 style="color:#FFF">Bienvenid@ a</h1>',
			html: '<img src="./assets/media/company-logos/logo-heilen-final-blanco.png" width="250" />',
			background: ` rgba(0,174,210, 0)`,
			backdrop: ` rgba(0,174,210,0.7)`,
			input: 'text',
			inputAttributes:{
				maxlength: '5',
				style:'background-color: #FFF'
			},
			inputPlaceholder: 'Ingresa tu código postal',
			confirmButtonText: 'Buscar',
			confirmButtonColor: '#f0a6c9',
			showClass: {
			  popup: 'animate__animated animate__fadeInDown'
			},
			hideClass: {
			  popup: 'animate__animated animate__fadeOutUp'
			},
			allowOutsideClick: true,
			willOpen : () => {
				Swal.getInput().onkeypress = (event) => {
				  return Number.isInteger(parseInt(event.key))
				}
			  },
			preConfirm: (cp) => {
				this.codigoPostal.setValue(cp);
				this.buscar();
			  },
		  }).then((result) => {
			  if(this.codigoPostal.value == ''){
				this.getLocation();
			  }
		  });
		this.locations = [];
		this.obtenerFarmacias();
		
		// Seteo de opciones filtradas de productos
		this.filteredOptions = this.producto.valueChanges
		      .pipe(
		        startWith(''),
		        map(value => this._filter(value))
			  );
		// Latitud y longitud preestablecidas para iniciar el mapa
		this.lat = 19.4321998;
		this.lng = -99.1339913;
		this.origin = { lat: this.lat, lng: this.lng };
	}

	private _randomImageUrls(images: Array<{id: number}>): Array<string> {
		return [1, 2, 3].map(() => {
			const randomId = images[Math.floor(Math.random() * images.length)].id;
			return `https://picsum.photos/900/500?image=${randomId}`;
		});
	}

	ngAfterViewInit(): void {
		this.splashScreenService.hide();
	}

	/**
	 * Obtener la lista de farmacias activas
	 */
	obtenerFarmacias(){
		this.mapsService.listaDistancia(this.lat, this.lng).subscribe(res => {
			this.locations = [];
			for(let i = 0; i < res.length; i++){
				this.locations.push(res[i]);
			}
			this.splashScreenService.hide();
			this.changeDetectorRefs.detectChanges();
		});
	}
	

	/**
	 * Filtro de productos
	 *
	 * @param value: Cadena ingresada del input de producto
	 */
	private _filter(value: string): string[] {
		const filterValue = value.toLowerCase();
		return this.options.filter(prod => prod.toLowerCase().includes(filterValue));
	}

	/**
	 * Función para mostrar opciones de autocompletar si se ingresan más de 2 caracteres
	 *
	 * @param prod: Cadena ingresada del input de producto
	 */
	updatedVal(prod) {
		if(prod.length >= 2) {
		   this.showAutocomplete = true;
		} 
		else {
		   this.showAutocomplete = false;
		}
	}
	
	/**
	 * Función para obtener la posición actual del navegador
	 */
	getLocation() {
		this.splashScreenService.show();
		console.log(2);
		let global = this;
		if (navigator.geolocation) {
			// Originalmente el timeout de esta función es infinito y por eso se tuvo que especificar para manejar
			// si el usuario no habilita la geolocalización del equipo.
			navigator.geolocation.getCurrentPosition(function (location) {
				Swal.close();
				global.lat = location.coords.latitude;
				global.lng = location.coords.longitude;
				global.obtenerFarmacias();
			},function(error){
				console.log(error);
				global.obtenerFarmacias();
			},{timeout:10000});
		}
		else {
			alert("La geolocalización no es soportada en este navegador.");
		}
	}

	/**
	 * Función para lanzar la búsqueda del producto ingresado y/o del código postal
	 */
	buscar() {
		this.splashScreenService.show();
		if(this.codigoPostal.value == ''){
			Swal.fire({
				title: `No se ingresó información para buscar.`,
				icon: 'error',
				showConfirmButton: true,
				confirmButtonText: 'Aceptar',
			}).then((result =>{
				this.getLocation();
			}));
			return;
		}
		if(this.codigoPostal.value.length<5){
			Swal.fire({
				title: `El código postal no es correcto. Favor de verificar`,
				icon: 'error',
				showConfirmButton: true,
				confirmButtonText: 'Aceptar',
			}).then((result =>{
				this.getLocation();
			}));
			return;
		}
		this.busqueda = true;
		this.mostrarRuta = false;
		if(this.producto.value == '' && this.codigoPostal.value != ''){
			this.mapsService.buscar(this.codigoPostal.value).subscribe(res => {
				if (res.results.length > 0) {
					const result = res.results[0];
					this.lat = result.geometry.location.lat;
					this.lng = result.geometry.location.lng;
					this.zoom = this.zoom-0.5;
					this.obtenerFarmacias();
					this.zoom = this.zoom+0.5;
				}
				else {
					Swal.fire({
						title: `El código postal ingresado no es correcto, favor de verificar.`,
						icon: 'error',
						showConfirmButton: true,
						confirmButtonText: 'Aceptar',
					});
					this.splashScreenService.hide();
					console.log(4);
					return;
				}
	
			},
			error => {
				console.log(error);
				Swal.fire({
					title: `Ocurrió un error, intentelo de nuevo.`,
					icon: 'error',
					showConfirmButton: true,
					confirmButtonText: 'Aceptar',
				})
				return;
			});
			return;
		}
	}

	/**
	 * Función para habilitar los campos de búsqueda.
	 */
	limpiar(){
		this.busqueda = false;
		this.mostrarRuta = false;
		this.producto.setValue('');
		this.codigoPostal.setValue('');
	}

	resetInfoWindow(index){
		if(this.openedWindow == index){
			this.openedWindow = -1;
		}
	}

	/**
	 * Función para calcular la distancia de la ubicación actual a las cóordenadas de una farmacia.
	 *
	 * @param x1: Latitud actual
	 * @param y1: Longitud actual
	 * @param x2: Latitud de farmacia
	 * @param y2: Longitud de farmacia
	 */
	distancia(x1: number, y1: number, x2: number, y2: number) {
		var result = 0;
		const RADIANS: number = 180 / 3.14159265;
		
		if (x1 == x2 && y1 == y2) {
		  result = 0;
		
		} else {
		  // Calculating Distance between Points
		  var lt1 = x1 / RADIANS;
		  var lg1 = y1 / RADIANS;
		  var lt2 = x2 / RADIANS;
		  var lg2 = y2 / RADIANS;
		
		  // Radio de la tierra en km * linea de punto a punto a medir...
		  result = (6371) * Math.acos(Math.sin(lt1) * Math.sin(lt2) + Math.cos(lt1) * Math.cos(lt2) * Math.cos(lg2 - lg1));
		}
		return result.toFixed(2);
	}

	// Función para mover el mapa a la farmacia seleccionada de la lista
	pinFarmacia(farmaciaLat, farmaciaLon, index){
		this.mostrarRuta = true;
		this.origin = { lat: this.lat, lng: this.lng };
		this.destination = { lat: farmaciaLat, lng: farmaciaLon };
		this.openWindow(index);
	}

	openWindow(id) {
	    this.openedWindow = id; // alternative: push to array of numbers
	}
	
	isInfoWindowOpen(id) {
	    return this.openedWindow == id; // alternative: check if id is in array
	}

	abrirModalContact(){
		let global=this;

		Swal.fire({
			title: '<h1>Contáctenos</h1>',
			html: '<p>Por favor contáctenos llenando el siguiente formulario. '+
			'Para obtener información adicional de HEILEN, visite la sección correspondiente a cada servicio.<p/>'+

			'<input id="nombre" maxlength="60" class="form-control" placeholder="Escriba su nombre" (keypress)="onlyLetras($event)"></br>' +
			'<input id="correo" maxlength="64" class="form-control" placeholder="Escriba su dirección de correo electrónico"></br>' +
    		'<textarea id="mensaje" maxlength="500" class="form-control" placeholder="Capture su mensaje"></textarea>',

			confirmButtonText: 'Enviar',
			confirmButtonColor: '#f0a6c9',
			showClass: {
			  popup: 'animate__animated animate__fadeInDown'
			},
			hideClass: {
			  popup: 'animate__animated animate__fadeOutUp'
			},
			//background: ` rgba(254,254,254,1)`,
			backdrop: ` rgba(254,254,254,1)`,
			willOpen : () => {
				$("#nombre").on('keypress',function(e) {
					if (/^[A-Za-zÁÉÍÓÚáéíóú\s]+/.test(e.key)) {
						return true;
					}
					return false;
				  });
			  },
			preConfirm: function () {
				global.splashScreenService.show();
				return new Promise((resolve) =>{
					let nombre = $('#nombre').val().toString();
					let correo = $('#correo').val().toString();
					let mensaje = $('#mensaje').val().toString();
					let correoValido = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(correo);
					if (correoValido&& mensaje.length>0) {
						let datos = {
							"name": nombre,
							"clientEmail": correo,
							"message": mensaje
						}
						resolve(datos);
					} else {
						global.splashScreenService.hide();
						let mensajeError= correoValido==false? '</br>El correo ingresado no es válido':'';
						mensajeError= mensajeError + (mensaje.length==0? '</br>Debe ingresar un mensaje':'');
						Swal.showValidationMessage(
							`Favor de validar: ${mensajeError}`
						  )
						$(".swal2-confirm").removeAttr('disabled'); 
					}
				})
			  },
		  }).then((result) => {
			  if(result.isConfirmed){
				this.mapsService.enviarCorreo(result.value).subscribe(res => {
					this.splashScreenService.hide();
					console.log(7);
					Swal.fire({
						icon: 'success',
						title: 'Mensaje enviado',
						text: 'El mensaje fue enviado correctamente'
					  })
				});
			  }
		  });
	}

	/**
	 * Función para validar que lo ingresado en el input solo son números.
	 *
	 * @param evt: Evento de tecla presionada
	 */
	onlyNumber(evt){
		evt = (evt) ? evt : window.event;
	    var charCode = (evt.which) ? evt.which : evt.keyCode;
	    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
	        return false;
	    }
	    return true;
	}

	/**
	 * Función para validar que lo ingresado en el input solo son letras.
	 *
	 * @param evt: Evento de tecla presionada
	 */
	onlyLetras(evt){
		evt = (evt) ? evt : window.event;
	    var charCode = (evt.which) ? evt.which : evt.keyCode;
	    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
	        return false;
	    }
	    return true;
	}
	
}
