// Angular
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
// Material
import { MatAutocompleteModule, MatButtonModule, MatCardModule, MatCheckboxModule, MatFormFieldModule, MatInputModule } from '@angular/material'

// Module components
import { MapComponent } from './map.component';
import { AgmCoreModule } from '@agm/core';
import { MatIconModule } from '@angular/material';
import { AgmDirectionModule } from 'agm-direction';
import { PartialsModule } from '../partials/layout/partials.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
const routes: Routes = [
	{
		path: '',
		component: MapComponent,
		children: [
			{
				path: '',
				redirectTo: '/',
				pathMatch: 'full'
			}
		]
	}
];


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatButtonModule,
		RouterModule.forChild(routes),
		MatInputModule,
		MatAutocompleteModule,
		MatFormFieldModule,
		MatCheckboxModule,
		MatIconModule,
		MatCardModule,
		AgmCoreModule.forRoot({
			apiKey: 'AIzaSyATVeqrQ1_qU8H5YsWffZly1UBQImon7dI',
			libraries: ["places", "geometry"]
		  }),
		AgmDirectionModule,
		PartialsModule,
		NgbModule
	],
	providers: [
	],
	exports: [],
	declarations: [
		MapComponent
	]
})

export class MapModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: MapModule,
			providers: [
			]
		};
	}
}