// Angular
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SplashScreenService } from '../../../../core/_base/services/splash-screen.service';

@Component({
	selector: 'kt-splash-screen',
	templateUrl: './splash-screen.component.html',
	styleUrls: ['./splash-screen.component.scss']
})
export class SplashScreenComponent implements OnInit {
	// Public proprties
	loaderLogo: string;
	loaderType: string;
	loaderMessage: string;

	@ViewChild('splashScreen', {static: true}) splashScreen: ElementRef;

	/**
	 * Component constructor
	 *
	 * @param el: ElementRef
	 * @param layoutConfigService: LayoutConfigService
	 * @param splashScreenService: SplachScreenService
	 */
	constructor(
		private splashScreenService: SplashScreenService,
		private el: ElementRef) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.loaderLogo = './assets/media/company-logos/logo-heilen-final-blanco.png';
		this.loaderMessage = 'Buscando...';
		this.splashScreenService.init(this.splashScreen);
	}
}
