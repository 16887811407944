// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  isMockEnabled: true, // You have to switch this, when your real back-end is done
  authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
  googleApi: 'https://maps.googleapis.com/maps/api/geocode/json',
  //facturacionUrl: 'https://qa.heilen.mx:2343/',
  facturacionUrl: 'https://facturacion.heilen.mx/',
  apiKey:'AIzaSyATVeqrQ1_qU8H5YsWffZly1UBQImon7dI',
  userId: 'usrId',

  host:'https://api.heilen.mx:1443/',
  //host:'https://qa.heilen.mx:1343/',
  //host:'https://api.heilen.mx:1443/',
  farmacias: {
    lista: 'Pharmacy/List',
    enviarCorreo: 'Pharmacy/SendEamil',
    listaDistancia: 'Pharmacy/ListDistance',
  }
};

