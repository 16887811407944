import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { PartialsModule } from './views/partials/layout/partials.module';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { MapModule } from './views/map/map.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GestureConfig, MatProgressSpinnerModule } from '@angular/material';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
		PartialsModule,
    MapModule.forRoot(),
    MatProgressSpinnerModule
  ],
  providers: [
    {
			provide: HAMMER_GESTURE_CONFIG,
			useClass: GestureConfig
		},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
