import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { FarmaciaModel } from '../crud/models/farmacias/farmacia.model';

@Injectable({
    providedIn: 'root'
  })
export class MapsService {
    private buscarURL = `${environment.googleApi}`;
    private listaURL = `${environment.host}${environment.farmacias.lista}`;
    private enviarCorreoURL = `${environment.host}${environment.farmacias.enviarCorreo}`;
    private listaDistanceURL = `${environment.host}${environment.farmacias.listaDistancia}`;

    constructor(private http: HttpClient) {}

    /**
	 * Servicio para obtener la lista de farmacias
	 *
	 */
    lista(): Observable<FarmaciaModel[]> {
        return this.http.get<FarmaciaModel[]>(`${this.listaURL}`);
    }

    /**
	 * Servicio para buscar dirección ingresada por CP
	 *
	 * @param codigo: Codigo postal ingresado
	 */
    buscar(codigo: string): Observable<any> {
        return this.http.get<any>(`${this.buscarURL}?address=${codigo} MX&key=${environment.apiKey}`);
    }

    /**
	 * Servicio para enviar correo de contacto
	 *
	 */
    enviarCorreo(datos): Observable<FarmaciaModel[]> {
        return this.http.post<FarmaciaModel[]>(`${this.enviarCorreoURL}`, datos);
    }

    /**
	 * Servicio para obtener la lista de farmacias con la distancia calculada de acuerdo al punto de origen
	 *
	 */
     listaDistancia(lat:string, lon:string): Observable<FarmaciaModel[]> {
        return this.http.get<FarmaciaModel[]>(`${this.listaDistanceURL}?lat=${lat}&lon=${lon}`);
    }
}
