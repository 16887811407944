// Angular
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Layout partials
import { SplashScreenComponent } from '../layout/splash-screen/splash-screen.component';
import { ContentAnimateDirective } from 'src/app/core/_base/layout/content-animate.directive';

@NgModule({
	declarations: [
		SplashScreenComponent,
		ContentAnimateDirective
	],
	exports: [
		SplashScreenComponent,
		ContentAnimateDirective
	],
	imports: [
		CommonModule,
		RouterModule
	],
})
export class PartialsModule {
}
